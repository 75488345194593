import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { getFormattedDateTime, getFormattedDuration } from 'utility';
import SETTINGS from 'configuration';
import { Alert, CircularProgress, ToggleButton, ToggleButtonGroup } from "@mui/material";

import DetailsDialog from "../DetailsDialog"
import TableHeaderWithTimeZoneToggle from 'components/TimeZoneToggle';


type ResponseObj = {
  nodes: Array<{
    node: Session
  }>
}

export type Session = {
  Nid: string;
  Title: string;
  'Start Time': string;
  Services?: string | { [key: number]: string };
  Duration: string;
  Impact?: string;
}

type Row = {
  id: string;
  title: string;
  startTime: string;
  endTime: string;
  services: string,
  duration: string;
  userImpact: string;
}

export const sessionsColumnsDefinition: GridColDef[] = [
  {
    field: 'startTime',
    headerName: 'Start Time (UTC)',
    width: 250,
    type: 'number',
    hideable: false,
    cellClassName: "monospaced-text"
  },
  {
    field: 'duration',
    headerName: 'Duration',
    width: 50,
    flex: 1,
    hideable: false
  },
  {
    field: 'services',
    headerName: 'Services',
    minWidth: 200,
    flex: 1,
    hideable: false
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 510,
    hideable: false
  },
  {
    field: 'userImpact',
    headerName: 'User Impact',
    width: 560,
    flex: 1,
    hideable: false
  },
];

const fetchSessions = async (): Promise<ResponseObj> => {
  const res = await fetch(SETTINGS.url.sessions);
  return await res.json();
}

function TimeSwitch(props: any) {

  return (
    <>
      <ToggleButtonGroup
        exclusive
        value={props.filterSessions.toString()}
        onChange={(e) => {
          props.handleChange((e.target as HTMLInputElement).value === "true")
        }
        }
      >
        <ToggleButton value="true">Upcoming or still running</ToggleButton>
        <ToggleButton value="false">Past</ToggleButton>
      </ToggleButtonGroup>
    </>

  )
}

export default function SystemSessions(props: { UTC: boolean; setUTC: any }) {
  const [timeFilter, setTimefilter] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [loadedData, setLoadedData] = useState<Array<Row> | null>(null);
  const [filteredData, setFilteredData] = useState<Array<Row> | null>(null);

  const [hasError, setHasError] = useState<string | null>(null);
  const [currentNid, setCurrentNid] = useState<string | null>(null);

  function sessionPopUp(e: GridRowParams<Row>) {
    setCurrentNid(e.row.id);
  }

  const getData = () => {
    setIsDataLoading(true);

    fetchSessions()
      .then((res) => {

        const sessions = res.nodes.map((item) => {
          // 'services' can be a string OR an object with strings as property '1', property '2' etc
          const nodeServices = item.node['Services'];

          const services = nodeServices ? (typeof nodeServices === 'object' ? Object.values(nodeServices) : [nodeServices]).join(", ") : ''
          return {
            id: item.node.Nid,
            title: item.node['Title'],
            startTime: item.node['Start Time'],
            services: services,
            endTime: (Number(item.node['Start Time'] + "000") + Number(item.node['Duration']) * 60000) + "",
            duration: getFormattedDuration(item.node['Duration']),
            userImpact: item.node['Impact'] ?? '',
          }
        })

        setLoadedData(sessions);
        setHasError(null);
      })
      .catch((error: any) => {
        setHasError(error.message);
      })
      .finally(() => {
        setIsDataLoading(false);
      })
  }

  useEffect(() => {
    getData();
    setInterval(getData, 30 * 1000);
  }, [])

  useEffect(() => {
    if (loadedData === null) return
    setFilteredData(loadedData.filter((item) => {
      return timeFilter !== (Number(item.endTime) < Date.now());
    }))
  }, [loadedData, timeFilter])

  // we flip the sort order according to the filter "past VS upcoming" sessions
  const sortOrder = timeFilter ? 'asc' : 'desc';
  // header of first row needs to change accoring to timezone 
  const columns = [...sessionsColumnsDefinition]
  columns[0].renderHeader = (column) => (<TableHeaderWithTimeZoneToggle UTC={props.UTC} setUTC={props.setUTC} />)
  columns[0].valueFormatter = (row) => getFormattedDateTime(row.value, props.UTC)

  return (
    <Box>
      <h2 className='section-header'>System Sessions</h2>
      <TimeSwitch
        filterSessions={timeFilter}
        handleChange={setTimefilter} />
      <Box sx={{ height: 533, width: '100%' }}>

        {
          isDataLoading && !loadedData &&
          <CircularProgress sx={{ display: "block", margin: "10px auto" }} />
        }

        {
          hasError &&
          <Box>
            <Alert severity="error"><b>Error fetching System Sessions!</b>
              <br />
              There might be a temporary network issue that prevents displaying the list of System Sessions.
              <br />
              Try to reload this page. If the problem persists, try again later.
            </Alert>
          </Box>
        }

        {
          !hasError && filteredData &&
          <DataGrid
            onRowClick={sessionPopUp}
            initialState={{
              sorting: {
                sortModel: [{ field: 'startTime', sort: sortOrder }],
              },
            }}
            sortModel={[{ field: 'startTime', sort: sortOrder }]}
            rows={filteredData}
            columns={columns}
            rowHeight={42}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        }
        <DetailsDialog UTC={props.UTC} Nid={currentNid} onClose={() => setCurrentNid(null)} />
      </Box>
    </Box>
  );
}
