import { Switch } from "@mui/material";

export default function TableHeaderWithTimeZoneToggle(props: any) {
  function toggleTimeZone(e: any) {
    e.stopPropagation();
    props.setUTC(e.target.checked);
  }
  return (
    <span>
      Post date (Local
      <Switch
        size='small'
        checked={props.UTC}
        onClick={(e) => { toggleTimeZone(e) }}
      />
      UTC)
    </span>
  );
}