import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper, { PaperProps } from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import StatusPanel from './StatusPanel';
import Notifications from './Notifications';
import SystemSessions from './SystemSessions';
import SpecialMessage from './SpecialMessage';
import { useEffect, useState } from 'react';
import SETTINGS from 'configuration';
import { getCookie } from 'utility';
import { RespStatusPage } from "./types";


const fetchMessage = async (): Promise<RespStatusPage> => {
  const sourceURL = SETTINGS.url.special_message
  const res = await fetch(sourceURL, { cache: "no-cache" })
  return res.json();
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const Item = (props: PaperProps) => {
  return <StyledPaper {...props} elevation={0} />
}

export default function ServiceStatusGrid() {
  const [message, setMessage] = useState('');
  const [showOriginal, setShowOriginal] = useState(false);

  const cookieValue = getCookie(SETTINGS.cookies.timezone);

  if (cookieValue === undefined ||
    (cookieValue !== "true" && cookieValue !== "false")) {
    document.cookie = `${SETTINGS.cookies.timezone}=true; Secure; SameSite=None;`;
  }

  const [UTC, setUTC] = useState<boolean>(getCookie(SETTINGS.cookies.timezone) === "true");

  function toggleUTC(value: boolean) {
    setUTC(() => { return value });
    document.cookie = `${SETTINGS.cookies.timezone}=${value}; Secure; SameSite=None;`;
  }

  const getData = () => {
    fetchMessage()
      .then((response) => {
        const startTime = new Date(response.data[0].attributes.field_session_date.value);
        const endtTime = new Date(response.data[0].attributes.field_session_date.end_value);
        if (startTime < new Date() && new Date() < endtTime) {
          setMessage(response.data[0].attributes.body.value);
          setShowOriginal(response.data[0].attributes.field_show_original_content);
        } else {
          setMessage('');
        }
      })
      .catch((response) => {
        // clear the message if something went wrong: we don't want to show a potentially stale message
        setMessage('');
      });
  }

  useEffect(() => {
    getData();
    setInterval(getData, 30 * 1000);
  }, [])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container direction="column"
        columns={12}
      >
        <Grid item xs={12}>
          <Item>
            <SpecialMessage content={message} />
          </Item>
        </Grid>
        {
          (message === '' || showOriginal) &&
          <>
            <Grid item xs={12}>
              <Item>
                <StatusPanel UTC={UTC} />
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item>
                <Notifications UTC={UTC} setUTC={toggleUTC} />
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item>
                <SystemSessions UTC={UTC} setUTC={toggleUTC} />
              </Item>
            </Grid>
          </>
        }
      </Grid>
    </Box>
  );
}
